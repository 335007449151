<template>
<div class="home-class">
  <div class="power-product-class">
  
      <!-- 头部部分 -->
      <div ref="search_d" style="margin-bottom:10px">
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="18">
            <el-button type="primary" @click="newBtn">新增</el-button>
            <exportBtn :params="expParams"/>
          </el-col>
          <el-col :span="6" style="display: flex;">
            <!-- <el-button type="primary" @click="exportClick">导出</el-button> -->
            <!-- <input type="file" id="default-btn" @change="importClick"> -->
           
            <el-input placeholder="请输入内容" v-model="queryInfo.condition.queryKey" class="input-with-select" clearable>
              <el-button slot="append" icon="el-icon-search" @click="getList"></el-button>
            </el-input>
          </el-col>
        </el-row>
      </div>
  
      <!-- 表格部分 -->
      <div>
        <el-table border ref="table" :data="tableData" tooltip-effect="dark" style="width: 100%" height="75vh">
          <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
  
  
            <el-table-column label="品牌名称" align="center" show-overflow-tooltip>
              <template slot-scope="scope">{{ scope.row.brandName }}</template>
            </el-table-column>
           
          <el-table-column label="操作" width="135" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="edit(scope.row)" size="small">编辑</el-button>
              <el-popconfirm title="确定删除吗？" @confirm="handleDelte(scope.row.id)">
                <el-button slot="reference" type="text">删除</el-button>
              </el-popconfirm>
              
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="block">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="queryInfo.currPage" :page-sizes="[100, 200, 300, 400]" :page-size="queryInfo.pageSize"
            class="pagination-style"
            layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </div>
  
      <el-dialog :title="(labelType=='add'?'新增':'编辑')" :visible.sync="showDialog" width="500px">
        <el-form class="form-class" ref="form" :model="form" :rules="rules" label-width="120px">
          <el-row :gutter="10">
              <el-col :span="20">
                <el-form-item label="品牌名称：" prop="siteName">
                  <el-input v-model="form.brandName" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
          </el-row>
        </el-form>
        <span slot="footer">
          <el-button size="small" @click="showDialog = false">取消</el-button>
          <el-button size="small" type="primary" @click="handleSave(labelTyp)">确定</el-button>
        </span>
      </el-dialog>
    </div>
</div>
</template>
  
<script>
import exportBtn from '@/components/exportBtn.vue'
  export default {
    data () {
      return {
        labelType: 'add',
        rules: {},
        total: 0,
        queryInfo: {
          pageSize: 100,
          pageCount: 1,
          currPage: 1,
          condition: {}
        },
        form: {},
        showDialog: false,
        tableData: [],
        height: window.innerHeight - 230, //表格高度
        headerHeight: 0,
        expParams:{
        url:'',
        fileName:''
      },
      };
    },
    components:{
      exportBtn
    },
  
    created () {
      this.getList()
    },
  
    mounted () {
      this.$nextTick(() => {
        // 获取定义ref属性 和 元素高度
        this.headerHeight = this.$refs.search_d.offsetHeight;
        window.addEventListener('resize', this.getHeight())
      })
    },
  
    methods: {
      // 监听表格高度
      getHeight () {
        this.height = window.innerHeight - this.headerHeight - 200;
      },
  
      //获取数据
      getList () {
        var that = this;
        that.$http.post("/brand/list", that.queryInfo).then(function (response) {
          console.log(response.data.data);
          if (response.data.code == 200) {
            that.total = response.data.data.count;
            that.queryInfo.currPage=response.data.data.currPage
            that.tableData = response.data.data.data;
          }
        });
      },
  
      // 保存
      handleSave (title) {
        console.log(this.labelType,'pp');
        if(this.labelType =='add'){
            var that = this;
        that.$http.post("/brand/add", that.form).then(function (response) {
            if (response.data.code == 200) {
                that.$notify.success({
                  title: "提示",
                  message: "保存成功",
                  showClose: true,
                });
               that.showDialog = false
                that.getList()
            }else{
                that.$notify.info({
                  title: "提示",
                  message: response.data.message,
                  showClose: true,
                });
            }
        }).catch(function(error){})
        } else {
            var that = this;
        that.$http.post("/brand/update", that.form).then(function (response) {
            if (response.data.code == 200) {
                that.$notify.success({
                  title: "提示",
                  message: "保存成功",
                  showClose: true,
                });
               that.showDialog = false
                that.getList()
            }else{
                that.$notify.info({
                  title: "提示",
                  message: response.data.message,
                  showClose: true,
                });
            }
        }).catch(function(error){})
        }

        
        
      },
  
      //修改
      edit (row) {
        this.showDialog = true;
        this.labelType = 'edit'
        this.form = JSON.parse(JSON.stringify(row))
      },
  
      // 删除当前行
  //    handleDelte (id) {
  //      del({ id: id }).then(res => {
  //        if (res.code == 200) {
   //         this.$message.success('删除成功')
   //         this.getList()
   //       }
  //      })
  //    },
      
      // 删除用户
      async handleDelte(id) {
        console.log('000000');
        const confirmResult = await this.$confirm("确定删除吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).catch((err) => err);
        if (confirmResult === "confirm") {
             var that = this;
          that.$http.post("/brand/delete" ,{"id":id}).then(function (response) {
              if(response.data.code == 200){
               that.$notify.success({
                  title: "提示",
                  message: "删除成功",
                  showClose: true,
                });
                that.getList();
              }else{
                   that.$notify.info({
                      title: "提示",
                      message: response.data.message,
                      showClose: true,
                    });
              }
           
          });
        }
      },
  
      //新增按钮
      newBtn () {
        this.labelType = 'add'
        this.showDialog = true;
        this.form = {}
      },
  
      // 修改页数大小
      handleSizeChange (val) {
        this.queryInfo.pageSize = val;
        this.getList()
      },
  
      // 获取当前页面
      handleCurrentChange (val) {
        this.queryInfo.currPage = val;
        this.getList()
      },
      // 导出 
      exportClick(){
            this.$http.post("powerStationDevice/Exp2").then(res => {
                if (res.data.code == 200) {
                    window.open(res.data.data.data);
                }
            });
        },
        async importClick(event){
            const file = event.target.files[0];
            // 使用 FormData 包装文件
            const formData = new FormData();
            formData.append('file', file);
            const response = await this.$http.post("powerStationDevice/export", formData, {
            headers: {
                'Content-Type': 'multipart/form-data', // 设置正确的Content-Type
            },
            });
            console.log('二进制文件',response)
        },
    },
  };
</script>
  
<style scoped lang="scss">
.home-class{
  background: #223f6c;
  padding: 10px;
  height: 100%;
  .power-product-class{
    height: 100%;
    background: #2d5981;
    padding: 8px;
    .form-class{
      /deep/ .el-form-item__label{
        background-color: rgba(255, 255, 255, 0);
        box-sizing: border-box;
        font-family: "微软雅黑", sans-serif;
        color: #333;
        text-align: right;
        font-size: 16px;
      }
      /deep/ .el-input__inner{
        border: 1px solid rgba(151, 179, 203, 0.55);
        background-color: rgba(255, 255, 255, 0);
        box-sizing: border-box;
        font-family: "Arial", sans-serif;
        color: #333;
        text-align: left;
        line-height: normal;
      }
    }
  }
}   
/deep/ .el-input-group__append{
  background: #409EFF;
  color: #fff;
}
/deep/ .el-input__inner{
  border: 1px solid rgba(151, 179, 203, 0.55);
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  color: #f2f2f2;
  text-align: left;
  line-height: normal;
}
/deep/ .el-table--border, .el-table--group {
  border: 1px solid rgba(151, 179, 203, 0.55);
}
/deep/ .el-table--border th{
  background-color: #21527e;
  border: 1px solid rgba(151, 179, 203, 0.55);
  box-sizing: border-box;
  font-family: "微软雅黑", sans-serif;
  color: #ffffff;
}
    
/deep/ .el-table th{
  background-color: #2d5981;
  border: 1px solid rgba(151, 179, 203, 0.55);
  box-sizing: border-box;
  font-family: "微软雅黑", sans-serif;
  color: #ffffff;
}
/deep/ .el-table td{
  background-color: #2d5981;
  border: 1px solid rgba(151, 179, 203, 0.55);
  box-sizing: border-box;
  font-family: "微软雅黑", sans-serif;
  color: #ffffff;
}
  /deep/ .el-table__body-wrapper{
    background: #2d5981;
  }
  ::v-deep
  .el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td {
  background-color: #223f6c !important;
}
  /deep/ .el-pagination{
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
    /deep/ .el-pagination button:disabled{
    background: rgba(255, 255, 255, 0);
  }
/deep/ .el-pager li.active {
  color: #f2f2f2;
  background-color: #00b2e6;
  cursor: default;
}
/deep/ .el-pager li{
  background: none;
  color: #f2f2f2;
 }
/deep/ .el-icon{
    color: #fff;
}

/deep/ .el-pagination .btn-prev{
    background: none;
}
/deep/ .el-pagination .btn-next{
    background: none;
}
/deep/ .el-pagination__total{
  color: #fff;
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  font-size: 12px;
}
/deep/ .el-pagination__jump{  
  color: #fff;
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  font-size: 15px;
}
/deep/ .el-upload {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
}
/deep/ .el-icon-close:before {
    color: #333;
}
   #default-btn::file-selector-button{
        padding: 5.5px 10px;
        background-color: #409EFF;
        border: 1px solid #409EFF;
        border-radius: 3px;
        cursor: pointer;
        color: #fff;
        font-size: 12px;
    }
#default-btn{
    font-size: 0;
    margin-left: 10px;
    margin-right: 6px;
    margin: 1px 6px 0 10px;
    
}
  </style>
  
  